import React from "react"
import Header from "../../components/services/servicesHeader"
import ServicesBottomNav from "../../components/services/servicesBottomNav"
import Footer from "../../components/footer"
import SEO from "../../components/seo"
import { Link } from "gatsby"

import Background from "../../../assets/images/servicesHeader/web_icon.svg"

import magnolia from "../../../assets/images/cms/magnolia.png"
import arrow from "../../../assets/images/portfolio_items/idday/arrow-back.png"

import elkuch from "../../../assets/images/cms/elkuch.png"
import fs from "../../../assets/images/cms/fs.png"

import img from "../../../assets/images/cms/img2.png"
import chart from "../../../assets/images/cms/chart.png"

class Drupal extends React.Component {
  
    render() {
      return (
    <div id="cms">
        <SEO title={'Magnolia Development Company | ICON Worldwide '} 
        description="Are you considering a Magnolia CMS build? If so, let's discover our Magnolia Development Company solutions that will make your enterprise evolve!"
        canonical={'https://icon-worldwide.com/services/magnolia-development-company'}/>
        <Header headerTitle="Magnolia CMS" headerSubTitle="Flexible and Lightening Fast"  headerBackground={Background}/>
        <div id="magnolia">
            <div className="cms-inner">
                <div className="left-part">
                    <h1>Discover the Magnolia Development Company and Certified Team that Will Make Your Enterprise Evolve</h1>
                    <h2 className="bigText">As a Magnolia Partner agency, with a <span>certified magnolia developer team</span> we continuously
                     invest and train our developers to stay in-front of the latest developments and best practices.</h2>
                    <p>Whether you are considering Magnolia CMS for a new project, or looking for an experienced team to support you with an existing project, our real, practical experience will prove invaluable. </p>
                    <p>Like us, the Magnolia CMS is made in Switzerland and offers a highly expert level of customer support, including architecture concepts and code reviews. This make Magnolia CMS an attractive 
                    alternative to open source CMS’s which rely on developer communities and third parts modules for support, security and extending functionality.</p>
                </div>
                <div className="right-part">
                    <div className="right-inner">
                        <img src={magnolia} alt="ICON Worldwide magnolia development company" title="ICON magnolia development company"/>
                        <h3>Are you considering a Magnolia CMS build?</h3>
                        <p>Talk to us, we’ll be happy to walk you through the pros and cons of Magnolia for your project.</p>
                        <div className="bigger-space">
                            <h3>Our staffing models</h3>
                            <p>We offer three models of cooperation:</p>
                            <h4>Project based</h4>
                            <h4>Time and materials</h4>
                            <h4>Dedicated team</h4>
                            <Link to="/contact-us"><div className="contact-btn">Let's start your new project together</div></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="silver-bg">
                <div className="cms-inner">
                    <div className="part">
                        <h2>Why Magnolia CMS?</h2>
                        <p>Magnolia is an award winning, fast, flexible and highly secure CMS built for the cloud. or on-premise hosting.
                            As a java based CMS, Magnolia is ideal for large scale, multi-faceted customer experience and can be used in
                            headless architectures. Built with a highly flexible architecture, open standards and a rich set of integration points,
                            you get full flexibility to employ microservices and to deliver experiences with a headless approach. Magnolia is fully
                            server rendered in any combination</p>
                    </div>
                    <div className="part">
                        <h2>An Advanced and Forward Thinking CMS</h2>
                        <p>While many open-sourced CMS’s are in a reactive state, chasing the latest functionalities, or responding to security issues with patches,
                        Magnolia CMS is a proven leader.</p>
                        <p> Automation powered by AI makes tasks such as tagging, search and personalization even faster, scalable and more effective.  </p>
                    </div>
                </div>
            </div>
            <div className="cms-inner last-inner">
                <div className="part">
                    <h2>Accelerate personalization and optimization</h2>
                    <p>Boost performance with Magnolia’s personalization and optimization capabilities. Actionable data and insights are tightly coupled to content and embedded 
                    in the authoring UI, speeding optimization cycles. </p>
                        <Link to="/works"><span>References <img src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/></span></Link>
                        <div className="logos">
                        <Link to="/works/frankfurt-school"><img src={fs} alt="ICON Worldwide magnolia development company" title=" ICON magnolia development company, fs"/></Link>
                        <Link to="/works/elkuch"><img src={elkuch} alt="ICON Worldwide magnolia development company" title=" ICON magnolia development company, elkuch"/></Link>
                        </div>
                </div>
                <div className="part">
                    <img className="full-img" src={img} alt="ICON Worldwide magnolia development company" title=" ICON magnolia development company, img"/>
                </div>
                <div className="yellowLine"></div>
                <ServicesBottomNav/>
            </div>
            </div>
        <Footer noScrollbar="true"/>
    </div>
    )
}
}

export default Drupal 